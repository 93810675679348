/* eslint-disable no-unused-vars */
import React from 'react'
import { graphql } from 'gatsby'
import PaginaContent from '../components/PaginaContent'
import Layout from '../components/Layout/index'
import Pagina from '../components/Pagina'

const Importados = ({ data }) => {
  const breadC = {
    visible: true,
    items: [
      { path: '/', name: 'Home' },
      { path: '/lgpd/', name: 'LGPD' }
    ]
  }

  return (
    <Layout breadCrumb={breadC} >
      <PaginaContent pagina={data.pagina} />
    </Layout>
  )
}

export default Importados

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/lgpd/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    pageSummary
    tags
    possuiBanner
    card {
      localFile {
        publicURL
      }
    }
    content {
      raw
    }
    desktop {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    mobile {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 414, height: 310, layout: CONSTRAINED)
        }
      }
    }
  }
}
`
