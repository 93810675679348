/* eslint-disable react/display-name */
import React, { Fragment, useState } from 'react'
import Banner from '../Banner'
import Helmet from 'react-helmet'
// import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

export default function PaginaContent (props) {
  const { pagina } = props
  const { content } = pagina

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        // If you are using contenful.js sdk, the referenced entry is resolved
        // automatically and is available at `node.data.target`.

        return <a href={`${node.data.uri}`} target="_blank" without rel="noreferrer"><u>{children}</u></a>
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const alt = node.data.target.fields.title['en-US']
        const url = node.data.target.fields.file['en-US'].url
        const [toggler, setToggler] = useState(false)
        return <figure className='figure'>
          <a onClick={() => setToggler(!toggler)}>
            <img className='figure-img img-fluid rounded border' alt={alt} src={url} title={alt} />
          </a>
        </figure>
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <p className='mb-3'>{children}</p>
      }
    },
    renderMark: {
      // eslint-disable-next-line react/display-name
      [MARKS.CODE]: text => {
        return <p>{text}</p>
      }
    }
  }

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="pt" />
        <title>{`${pagina.metaTitle} | Spani Atacadista `}</title>
        <meta name="title" content={`${pagina.metaTitle} | - Spani Atacadista`} />
        <meta name="description" content={pagina.metaDescription} />
        <meta name="keywords" content={pagina.tags.join(', ')} />
        <meta name="author" content="Spani" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={`${pagina.metaTitle} | - Spani Atacadista`} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://www.spani.com.br${pagina.path}`} />
        <meta
          property="og:image"
          content={`www.spani.com.br${pagina.card.localFile.publicURL}`}
        />
        <meta property="og:description" content={pagina.metaDescription} />
      </Helmet>
      {pagina.possuiBanner &&
      <>
        <Banner
          desktop={pagina.desktop.localFile.childImageSharp.gatsbyImageData}
          mobile={pagina.mobile.localFile.childImageSharp.gatsbyImageData}
          alt="Banner"
        />
        <div className="container">
          <p className='m-0 mt-lg-4 mb-4 font-weight-bold' >
            {content && renderRichText(content, options)}
          </p>
        </div>
      </>
      }
    </Fragment>
  );
}
